/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: false // Set to false to use static SVG
};

const greeting = {
  username: "Aditya B.",
  title: "Hi all, I'm Aditya",
  subTitle: emoji(
    "A dedicated AI and Software Engineer with a perpetual passion for DevOps. Proven record in implementing AI solutions to enhance real-world problems 🤖 and streamline deployment processes 🚀."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1bDt98L1Zqoand3spS5OCQMmAnZK4ggFQ/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/AdityaBhanwadiya",
  linkedin: "https://www.linkedin.com/in/aditya-bhanwadiya/",
  gmail: "bhanwadiya.aditya@gmail.com",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY TECHIE WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ I build AI-driven systems that replace inefficiency with seamless automation."
    ),
    emoji("⚡ From microservices to cloud-native architectures, I design systems that grow effortlessly."),
    emoji(
      "⚡ Great code is useless if it isn’t understood—communication is key to real innovation."
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "azure",
      fontAwesomeClassname: "fab fa-microsoft"
    },
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "c++",
      fontAwesomeClassname: "fas fa-code"
    },
    {
      skillName: "mongodb",
      fontAwesomeClassname: "fas fa-database"
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Northeastern University",
      logo: require("./assets/images/neulogo.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "January 2023 - May 2025",
      desc: "Coursework:",
      descBullets: [
        "Programming Design Paradigm", "Database Management Systems", "Algorithms", "Machine Learning", "Introduction to Data Management and Processing", "Cloud Computing", "Web Development", "Human Computer Interaction"

      ]
    },
    {
      schoolName: "Dharmsinh Desai University",
      logo: require("./assets/images/ddulogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2018 - May 2022",
      desc: "Coursework:",
      descBullets: [
        "Artificial Intelligence",
        "Distributed Computing",
        "Computer Networks",
        "E-Commerce and E-Security",
        "Operating systems",
        "Object-Oriented Programming",
        "Data and File Structures",
        "Algorithms",
        "Data Mining and Data Warehousing",
        "Database Management System",
        "Software Engineering",
        "Web Technologies (.net)",
        "Advanced Technologies (J2EE, Spring, Hadoop, Hibernate, JS Frameworks)",
        "Design Patterns",
        "System Design and Practice",
        "Computer Architecture",
        "Computer Peripherals and Interfacing",
        "Theory of Computation",
        "Microprocessors",
        "Communication Systems",
        "Digital Electronics",
        "Assembly Language Programming",
        "Compiler Design",
        "Algebra",
        "Calculus",
        "Graph Theory",
        "Probability"
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineering Intern",
      company: "Beround",
      companylogo: require("./assets/images/beroundlogo.png"),
      date: "June 2024 – December 2024",
      descBullets: [
        "Reduced processing time from 60 minutes to 2 minutes by launching a new AI-driven onboarding solution, scaling from PoC to full-scale deployment—through cross-functional collaboration, leveraging Python and Azure AI Services",
        "Ensured over 95% field-level accuracy in Salesforce by developing an Intelligent Handwritten Document Processor through Azure Cognitive Services, Logic Apps and LLMs for validation, reducing false positives through a fine-tuned pipeline.",
        "Scaled from 200 to 1,000+ documents daily by engineering a fault-tolerant distributed system with event-driven processing, asynchronous workflows, and centralized monitoring, and automated CI/CD deployment using Terraform."
    ]
    },
    {
      role: "Head Teaching Assistant",
      company: "Northeastern University",
      companylogo: require("./assets/images/neulogo.png"),
      date: "Sept 2023 – May 2024",
      descBullets: [
        "Mentored 300+ students across database-focused courses, emphasizing SQL optimization, Relational Databases design, ACID principles, and NoSQL concepts, while fostering expertise in indexing, stored procedures, and transactions",
        "Optimized MySQL servers for database integration, developing SQL-based ETL pipelines for structured data processing and experimenting with graph databases for relationship-driven queries in distributed systems."
      ]
      
    },
    {
      role: "Software Engineer Intern",
      company: "Accenture",
      companylogo: require("./assets/images/accenture_new.png"),
      date: "June 2022 – December 2022",
      descBullets: [
        "Boosted first-contact resolution by 22% for 150K+ users by optimizing microservices, reducing API inference load via distributed processing, and improving system reliability.",
        "Processed 300K+ messages and enhanced scalability by integrating Apache Kafka with Java (Spring Boot, Spring Cloud) and PostgreSQL, streamlining asynchronous data flow for distributed systems."
        ]
    },
    {
      role: "Software Engineer Intern",
      company: "Indian Space Research Organization",
      companylogo: require("./assets/images/isrologo.png"),
      date: "December 2021 – April 2022",
      descBullets: 
      [ "Developed an LSTM-CNN ensemble for evapotranspiration forecasting, surpassing SARIMA in predictive accuracy by integrating CNNs for feature extraction and LSTMs for sequential learning in time-series analysis.",
        "Trained and validated in TensorFlow using cross-validation and hyperparameter tuning to enhance forecasting reliability."
       ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  
  projects: [
    {
        // image: require("./assets/images/canvas.png"),
        "projectName": "Kanbas: Learning Management System",
        "projectDesc": "Revamped a full-stack e-learning platform using ReactJS, modularizing UI components and refactoring state management with Redux to eliminate excessive prop passing. Reduced backend load and improved response times by caching frequent API calls with Redis LRU caching. Automated CI/CD pipelines with Jenkins and Docker for streamlined deployments across 8+ backend services."
    },
    {
        "projectName": "FraudShield",
        "projectDesc": "Built a real-time fraud detection system leveraging machine learning and rule-based heuristics, enabling bulk transaction analysis for businesses and real-time consumer fraud alerts via Plaid API and SMTP notifications. Enhanced fraud detection accuracy by applying SMOTE for class imbalance, streamlining real-time transaction processing with Flask, and optimizing MongoDB queries, reducing false positives by 40% while maintaining sub-second response times."
    },
    {
        "projectName": "Real Estate Hub",
        "projectDesc": "Built a full-stack real estate marketplace with property listings, JWT-secured API and optimized search using MongoDB text indexes and Redux for state management. Integrated Google OAuth via Firebase Authentication, handled image uploads with Firebase Storage, enabled real-time listings with MongoDB change streams, and deployed on Render for scalability"
    },
    {
        "projectName": "FortiFi",
        "projectDesc": "Developed a cloud-native AI-driven fraud detection pipeline, leveraging Azure Event Hub and ML risk models to reduce false positives and unauthorized transactions. Enhanced real-time security monitoring by integrating event-driven anomaly detection with Microsoft Sentinel, enabling faster threat response and automated incident analysis"
    },
    {
        "projectName": "CarReserve",
        "projectDesc": "Developed a cloud-native car reservation system with Azure AD B2C authentication, car listing, booking management, and email notifications, leveraging CosmosDB and SendGrid for efficient data storage and transactional messaging. Implemented event-driven architecture with Azure Service Bus, secured APIs via Azure API Management, and enhanced observability using Application Insights for logging and telemetry."
    },
    {
        "projectName": "StudyVoice",
        "projectDesc": "Built a Flask-based assistive tool for converting live lectures into structured notes, leveraging SpeechRecognition for real-time transcription and GeminiAPI for text summarization to enhance accessibility for students. Implemented real-time audio capture with MediaStream API and built a custom WAV conversion pipeline in JavaScript to ensure compatibility with SpeechRecognition for seamless transcription and note storage"
    },
    {
        "projectName": "Image Manipulation Application",
        "projectDesc": "Developed a desktop-based image editor using Java Swing, applying SOLID principles and following MVC where applicable to ensure modularity and maintainability. Implemented Test-Driven Development for backend logic, optimized image processing with parallel computations, and ensured robustness with extensive JUnit test coverage for core processing."
    },
    {
        "projectName": "Health Plan Recommendation System",
        "projectDesc": "Developed an NLP-based system that classifies health conditions from user-input symptoms, leveraging TF-IDF and ML models to provide structured health plan recommendations. Trained and compared Naive Bayes, Passive Aggressive Classifier, and TF-IDF with 3 n-grams, achieving 91.16% test accuracy using Precision-Recall evaluation on symptom-based health classification"
    },
    {
        "projectName": "Robot Pathfinding",
        "projectDesc": "Developed an AI-powered pathfinding system for hospital robots, implementing the A* algorithm to compute optimal routes dynamically and visualize movement using JavaFX-based interactive hospital layouts. Designed a user-friendly administrator tool to map hospital layouts, modify routes, and analyze shortest paths with cost evaluations, integrating automated error handling and email notifications for inaccessible paths."
    },
    {
        "projectName": "Emotional AI",
        "projectDesc": "Trained a CNN in TensorFlow/Keras to predict 15 facial keypoints from 96x96 grayscale images, achieving an MSE of 0.02 on the validation set, enabling accurate facial landmark detection for real-world applications. Improved model robustness by normalization, augmentation, hyperparameter tuning, and dropout regularization, efficiently processing 7,000+ images for accurate, deployment-ready facial analysis."
    },
    {
        "projectName": "FastBYTE",
        "projectDesc": "Built a high-concurrency order processing system for a university canteen, leveraging Spring MVC and deploying on AWS EC2 with Auto Scaling to handle high user traffic efficiently. Optimized inventory tracking using event-driven architecture with Apache Kafka, ensuring consistency via transactional locking (Hibernate) and improving data retrieval with GraphQL for dynamic client queries"
    },
    {
        "projectName": "WanderKeep",
        "projectDesc": "Built a web app for saving and organizing locations with descriptions, images, and categories using Django, Mapbox, and Google API for real-time mapping and reverse geocoding. Implemented dynamic map updates and seamless CRUD operations with Django REST Framework, AJAX, and JavaScript, enabling real-time place management without page reloads."
    },
    {
        "projectName": "GrowMore",
        "projectDesc": "Built a cloud-based Android marketplace for direct farm-to-consumer sales, integrating Google Pay API for secure transactions and Firebase Firestore for real-time order tracking and inventory updates. Ensured app stability with JUnit and Espresso testing, using Firebase Cloud Functions for automated order processing and refining UI/UX with MVVM architecture and Jetpack Components."
    },
    {
        "projectName": "CurioTech",
        "projectDesc": "Built an interactive learning app introducing kids (ages 5-12) to technology, UI/UX, and app development through pre-rendered animated lessons, interactive quizzes, and gamified challenges. Developed a cross-platform Flutter app with Firebase Authentication and Firestore, embedding Lottie-based After Effects animations and Canva assets for an engaging, child-friendly experience"
    },
    {
        "projectName": "First Responder",
        "projectDesc": "Built a real-time medical assistant enabling instant symptom analysis and emergency triage, integrating voice-based input, AI-driven assessments, and real-time data processing for faster and more accurate patient assistance. Built a real-time hospital routing system using Google Maps API, dynamically connecting patients to the nearest hospitals based on real-time location data"
    },
    {
        "projectName": "Simplified-Drive",
        "projectDesc": "Developed a cloud-based file storage system, enabling users to securely upload, retrieve, update, and delete files, leveraging AWS S3 for scalable storage and CloudFront for accelerated content delivery. Implemented an event-driven architecture using AWS Lambda and SNS for automated notifications, secured APIs with Amazon API Gateway, and deployed on Elastic Beanstalk with auto-scaling and load balancing for high availability."
    },
    {
        "projectName": "NeuraVibe",
        "projectDesc": "Designed a mindful user feed in React Native–Node.js, enforcing a 30-post limit and optimizing engagement through content curation while securing sessions via JWT authentication. Implemented NoSQL indexing for curated search and enabled real-time messaging with Firebase Cloud Messaging to enhance user engagement and platform reliability"
    }
]
,
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "All India Rank - 03",
      subtitle:
        "All India Rank (AIR) 03 in NCAT (National Creative Aptitude Test 2021) Exam Category 3 out of >5000 students.",
      image: require("./assets/images/ncatlogo.png"),
      imageAlt: "National Creative Aptitude Test",
      footerLink: [
        
      ]
    },
    {
      title: "Best Performer at Accenture",
      subtitle:
        "Recognized as one of the best performers in 2022 Q4 - specifically for being a fast learner - by the Solution Architect Sr. Manager.",
      image: require("./assets/images/accenture_new.png"),
      imageAlt: "Accenture",
      footerLink: [
      ]
    },
    {
      title: "Salesforce Certifications",
      subtitle:
        "- Salesforce Certified Administrator\n- Salesforce Certified Omnistudio Consultant\n- Salesforce Certified Omnistudio Developer",
      image: require("./assets/images/salesforce.png"),
      imageAlt: "Accenture",
      footerLink: []
    },    
    {
      title: "AWS Certified Solution Architect (SAA)*",
      subtitle:
        "Focused on the design of cost and performance optimized solutions.",
      image: require("./assets/images/aws.png"),
      imageAlt: "AWS SAA",
      footerLink: [
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Publications",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://doi.org//10.32628/CSEIT21761",
      title: "Layman-Friendly Regional Language Unit Conversion Compiler",
      description:
        "IJSRCSEIT- Volume 7, Issue 6, November-December 2021"
    },
    {
      url: "https://doi.org/10.22214/ijraset.2021.36904",
      title: "Design and Implementation of an Android Application for Betterment of Farmers",
      description:
        "IJRASET- Volume 9, Issue VII, July 2021"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "I enjoy talking to new people so feel free to mail or connect with me",
  number: "+1 (857) 337-4957",
  email_address: "bhanwadiya.aditya@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};